<template>
  <v-layout full-width fill-height>
    <v-container v-if="!isInitialLoading" fluid class="pt-0">
      <v-card
        elevation="4"
        class="px-5 py-4 glb-border-top"
        rounded
        :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
        :dark="$store.getters.theme === 'dark' ? true : false"
      >
        <v-row align="center" justify="end">
          <v-col cols="auto">
            <v-btn
              elevation="0"
              color="transparent"
              x-large
              class="btn-disable px-0"
            >
              <v-icon class="mr-2"> mdi-folder</v-icon>
              <span class="">{{ folderName }} </span>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="px-sm-0 ml-auto" sm="auto">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="createFolderDialog = true" v-on="on">
                  <v-icon size="20">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Rename</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  :loading="deleteFolderLoading"
                  icon
                  @click="deleteFolder()"
                  v-on="on"
                >
                  <v-icon size="20">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon @click="refresh()" v-on="on">
                  <v-icon size="20">mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh</span>
            </v-tooltip> -->
          </v-col>
          <v-spacer class="d-none d-sm-flex"></v-spacer>
          <v-col cols="auto" v-if="totalCount">
            <div class="text-body-2">
              <v-row align="center" class="ma-0" no-gutters>
                <v-btn text @click="refresh()">
                  <v-icon left>mdi-refresh</v-icon>
                  Refresh
                </v-btn>
                <v-col cols="auto ">
                  {{ from }} - {{ to }} of
                  {{ totalCount }}
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    icon
                    :disabled="from > 1 ? false : true"
                    @click="getFolderData(false, --options.page)"
                  >
                    <v-icon> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-btn icon :disabled="to >= totalCount ? true : false">
                    <v-icon @click="getFolderData(false, ++options.page)">
                      mdi-chevron-right
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>

      <v-card
        elevation="0"
        :dark="$store.getters.theme === 'dark' ? true : false"
        class="mail-view-box glb-border-top my-5"
        :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
      >
        <v-card v-if="showMails.length == 0" color="transparent" elevation="0">
          <v-card-text> No Mails Found </v-card-text>
        </v-card>

        <v-data-table
          class="mail-data-table"
          :headers="headers"
          :items="showMails"
          :options="options"
          hide-default-footer
          hide-default-header
          :server-items-length="totalCount"
          :loading="isInitialLoading"
          :footer-props="{
            'items-per-page-options': [50],
            'items-per-page-text': 'Mails to show',
          }"
          item-key="parentId"
        >
          <template v-slot:body="{ items }">
            <tr
              class="tr"
              :class="
                item.seen && item.seen.includes(userInfo.nickName)
                  ? 'read-mail'
                  : ''
              "
              v-for="[index, item] in items.entries()"
              :key="item.parentId"
            >
              <td
                @click="checkItem(item, index)"
                class="td td-mail"
                :title="getSingleUserInfo(item.receiver[0])"
              >
                <v-list-item class="px-0">
                  <template
                    v-if="
                      (userInfo.nickName == item.sender ||
                        userInfo.domains.includes(item.sender)) &&
                      item.receiver.length == 1
                    "
                  >
                    <v-avatar size="40" class="main-btn mr-3">
                      <!-- {{
                        item.sender
                          ? item.sender.slice(1, 3).toUpperCase()
                          : "##"
                      }} -->
                      {{ item.receiver[0].slice(1, 3).toUpperCase() }}
                    </v-avatar>
                  </template>
                  <!-- <template v-else-if="item.receiver.length > 1"> -->
                  <template
                    v-else-if="
                      userInfo.nickName == item.sender ||
                      userInfo.domains.includes(item.sender)
                    "
                  >
                    <span class="multi-avatar">
                      <v-avatar size="40" class="main-btn mr-3 first-avatar">
                        {{ item.receiver[0].slice(1, 3).toUpperCase() }}
                      </v-avatar>
                      <v-avatar size="40" class="main-btn second-avatar">
                        ##
                      </v-avatar>
                    </span>
                  </template>
                  <template v-else>
                    <v-avatar size="40" class="main-btn mr-3">
                      <!-- {{
                        item.receiver[0]
                          ? item.receiver[0].slice(1, 3).toUpperCase()
                          : "##"
                      }} -->
                      {{
                        item.sender
                          ? item.sender.slice(1, 3).toUpperCase()
                          : "##"
                      }}
                    </v-avatar>
                  </template>

                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <span
                        v-if="
                          userInfo.nickName == item.sender ||
                          userInfo.domains.includes(item.sender)
                        "
                      >
                        <span
                          v-for="(rec, index) in item.receiver"
                          :title="getSingleUserInfo(rec, item)"
                          :key="index"
                        >
                          {{ rec }}
                          <span v-if="index !== item.receiver.length - 1"
                            >,
                          </span>
                        </span>
                      </span>
                      <span
                        :title="getSingleUserInfo(item.sender, item)"
                        v-else
                      >
                        {{ item.sender }}
                      </span>
                    </v-list-item-title>

                    <div class="mt-2">
                      <v-chip
                        v-if="$route.name !== 'inbox' && $route.name !== 'sent'"
                        small
                        class="mr-1"
                      >
                        {{
                          userInfo.nickName == item.sender ||
                          userInfo.domains.includes(item.sender)
                            ? "sent"
                            : "inbox"
                        }}
                      </v-chip>
                      <v-btn
                        v-if="item.attachments.length"
                        class="mr-1 pa-0"
                        elevation="0"
                        min-width="auto"
                        color="transparent"
                        :title="
                          'Has ' + item.attachments.length + ' attachment(s)'
                        "
                      >
                        <v-icon small>mdi-paperclip</v-icon>
                        <small>x</small> {{ item.attachments.length }}
                      </v-btn>

                      <span class="mail-subject">{{ item.subject }}</span>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td class="td td-time">
                <span class="additions">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="undoFile(item)" v-on="on">
                        <v-icon> mdi-package-up </v-icon>
                      </v-btn>
                    </template>
                    <span>Undo</span>
                  </v-tooltip>
                </span>
                <span class="time">
                  {{ timeFormate(item.timestamp) }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-dialog
          width="500"
          max-width="90%"
          :close.sync="createFolderDialog"
          v-model="createFolderDialog"
        >
          <edit-folder
            :createFolderDialog="createFolderDialog"
            @closeDialog="createFolderDialog = false"
            :folderTagName="$route.params.tagName"
          >
          </edit-folder>
        </v-dialog>
      </v-card>
    </v-container>

    <v-card
      v-else
      width="100%"
      :dark="$store.getters.theme === 'dark' ? true : false"
      class="mail-view-box glb-border-top pa-5 text-center"
      :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
    >
      <div>Fetching {{ folderName }} Mails, Please wait</div>
    </v-card>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import EditFolder from "../dashboard-components/EditFolder.vue";

export default {
  components: { EditFolder },
  created() {
    this.isInitialLoading = true;
    this.getFolderData();
  },
  data() {
    return {
      isInitialLoading: false,

      viewPortClass: "view-container",
      options: { itemsPerPage: 50, page: 1 },
      tableLoading: false,
      createFolderDialog: false,
      deleteFolderLoading: false,
      headers: [
        { value: "sender" },
        { value: "subject" },
        { value: "timestamp" },
      ],
      showMails: [],
    };
  },
  // watch: {
  //   options() {
  //     this.getFolderData();
  //   },
  // },
  methods: {
    getSingleUserInfo(lookUpName) {
      if (!lookUpName) return "";

      if (lookUpName == this.userInfo.nickName)
        return "Me <" + lookUpName + ">";
      if (this.userInfo.domains.includes(lookUpName))
        return "Me <" + lookUpName + ">";

      lookUpName = lookUpName.toLowerCase();

      const senderDetails = this.userNames[lookUpName];

      if (
        !senderDetails ||
        !senderDetails.firstName ||
        senderDetails.firstName == ""
      )
        return "<" + lookUpName + ">";

      return (
        senderDetails.firstName +
        " " +
        senderDetails.lastName +
        " " +
        "<" +
        lookUpName +
        ">"
      );
    },
    getFolderData(force = false, pageNo) {
      if (pageNo) this.options.page = pageNo;

      this.tableLoading = true;
      this.$store
        .dispatch("GET_FOLDER_MAILS", {
          limit: this.options.itemsPerPage,
          pageNo: this.options.page,
          tagName: this.$route.params.tagName,
          force,
        })
        .then(() => {
          this.tableLoading = false;
          setTimeout(() => {
            this.resetShowMails({ isInitialLoading: false });
          }, 100);
        });
    },
    refresh() {
      this.getFolderData(true);
      // this.$store.dispatch("REFRESH_MAILS", "ARCHIVE");
    },
    deleteFolder() {
      if (this.totalCount) return this.$toast.error("Folder is not empty");

      this.deleteFolderLoading = true;
      this.$store
        .dispatch("DELETE_FOLDER", this.folderTagName)
        .then(() => {
          this.deleteFolderLoading = false;
          this.$router.push("/");
          this.$toast.success("Folder Deleted Successfully");
        })
        .catch((error) => {
          this.$toast.error(error);
          this.deleteFolderLoading = false;
        });
    },
    undoFile(archiveMail) {
      let index = this.totalMails.findIndex(
        (name) => name._id == archiveMail._id
      );

      this.$store.getters.userFolderDetails[
        this.$route.params.tagName
      ].mails.splice(index, 1);
      this.$store.getters.userFolderDetails[
        this.$route.params.tagName
      ].totalCount -= 1;
      this.resetShowMails();

      this.$toast(`Removing mail from Folder`);
      this.$store.dispatch("REMOVE_FROM_ARCHIVE", archiveMail);

      setTimeout(() => {
        this.$store.dispatch("UPDATE_MAIL", {
          operationCode: 3,
          emailObjectId: archiveMail._id,
        });
      }, 0);
    },
    checkItem(item) {
      this.$router.push(
        `/mail/folder/${this.$route.params.tagName}/${item.parentId}`
      );
    },
    timeFormate(date) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      date = new Date(date);
      var OneDay = new Date().getTime() - 1 * 12 * 60 * 60 * 1000;
      // var OneDay = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
      OneDay = new Date(OneDay);

      if (OneDay < date) {
        var hours = date.getHours(),
          minutes = date.getMinutes(),
          am_pm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const strTime = hours + ":" + minutes + " " + am_pm;

        return strTime;
      } else {
        var month = "" + monthNames[date.getMonth()],
          day = "" + date.getDate();
        // year = date.getFullYear();

        month = month.substr(0, 3);
        if (day.length < 2) day = "0" + day;
        const strTime = [day, month].join(" ");

        return strTime;
      }
    },
    resetShowMails(data) {
      let totalMails = this.userFolderDetails[this.$route.params.tagName]
        ? this.userFolderDetails[this.$route.params.tagName].mails
        : [];

      this.showMails = totalMails.slice(
        this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage,
        this.options.page * this.options.itemsPerPage
      );

      if (data && data.isInitialLoading)
        this.isInitialLoading = data.isInitialLoading;
      else this.isInitialLoading = false;
    },
  },
  // updated() {
  //   if (!this.$store.getters.userFolderDetails[this.$route.params.tagName])
  //     return;
  //   this.showMails = this.$store.getters.userFolderDetails[
  //     this.$route.params.tagName
  //   ].mails;
  //   this.totalCount = this.$store.getters.userFolderDetails[
  //     this.$route.params.tagName
  //   ].totalCount;
  // },
  computed: {
    userNames() {
      return this.$store.getters.userNames;
    },
    folderTagName() {
      return this.$route.params.tagName;
    },
    folderName() {
      return this.$store.getters.foldersList[this.$route.params.tagName];
    },
    totalCount() {
      return this.$store.getters.userFolderDetails &&
        this.$store.getters.userFolderDetails[this.$route.params.tagName]
        ? this.$store.getters.userFolderDetails[this.$route.params.tagName]
            .totalCount
        : 0;
    },
    ...mapGetters({ userFolderDetails: "userFolderDetails" }),
    totalMails() {
      return this.userFolderDetails[this.$route.params.tagName]
        ? this.userFolderDetails[this.$route.params.tagName].mails
        : [];
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    from() {
      return this.options.itemsPerPage * (this.options.page - 1) + 1;
    },
    to() {
      return this.from + this.options.itemsPerPage - 1 < this.totalCount
        ? this.from + this.options.itemsPerPage - 1
        : this.totalCount;
    },
  },
};
</script>

<style lang="scss" scoped>
.tr {
  .additions {
    display: none;
  }
  .time {
    display: block;
  }

  &:hover {
    background: #00000080;
    cursor: pointer;

    td:first-child {
      border-left: 5px solid #2196f3;
    }

    .additions {
      display: block;
    }
    .time {
      display: none;
    }
  }
}

.list-container {
  width: 400px !important;
  background: white !important;
  z-index: 1;
}

.view-container {
  z-index: 0;
  background: white !important;
  width: 100% !important;
  position: absolute !important;
  height: 100%;
  padding-left: 400px !important;
}

@media (max-width: 768px) {
  .list-container {
    width: 100% !important;
  }

  .z-imp {
    z-index: 2 !important;
  }

  .view-container {
    padding-left: 15px !important;
  }
}

::v-deep .v-data-table-header {
  display: none !important;
}

.td {
  padding: 10px 15px;
  font-size: 0.9em;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.td-mail {
  width: 80%;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.td-subj {
  min-width: 60%;
  padding-right: 0;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.td-time {
  padding-left: 0;
  padding-right: 10px;
  width: 80px;
  text-align: right;
}
.folder-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.mail-data-table {
  background: transparent !important;
}

.multi-avatar {
  position: relative;

  .second-avatar {
    position: absolute;
    left: 5px;
    top: -1px;
    z-index: 0;
    // box-shadow: 1px 0px 0px 1px white;
  }
  .first-avatar {
    z-index: 1;
    position: relative;
    // box-shadow: 1px 0px 0px 1px white;
  }
}

.glb-dark {
  .second-avatar,
  .first-avatar {
    box-shadow: 1px 0px 0px 1px black;
  }
}

.glb-white {
  .second-avatar,
  .first-avatar {
    box-shadow: 1px 0px 0px 1px white;
  }
}
</style>
