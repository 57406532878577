<template>
  <v-form ref="form" v-if="createFolderDialog">
    <v-card>
      <v-card-text class="pa-0">
        <v-text-field
          class="mx-5 pt-5 pb-0"
          label="Folder Name "
          required
          autofocus
          outlined
          ref="folderRef"
          v-model.trim="folderName"
          :rules="[
            (v) => (v && v.length > 2) || 'Minimum 3 characters required',
            (v) => (v && v.length < 31) || 'Max 30 characters allowed',
          ]"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red"
          :loading="createFolderLoading"
          text
          @click="closeDialog()"
        >
          Discard
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="createFolderLoading"
          :disabled="
            folderName.length < 3 ||
            folderName.length > 30 ||
            $store.getters.foldersList[this.folderTagName] === folderName
          "
          @click="CreateFolderFn()"
        >
          <span v-if="folderTagName"> Update </span>
          <span v-else> Create </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    createFolderDialog: {
      type: Boolean,
      default: false,
    },
    folderTagName: {
      required: false,
    },
  },
  data() {
    return {
      createFolderLoading: false,
      folderName: this.$store.getters.foldersList[this.folderTagName] || "",
      folderTag: "",
    };
  },
  watch: {
    folderTagName() {
      this.folderName =
        this.$store.getters.foldersList[this.folderTagName] || "";
    },
  },
  created() {},
  methods: {
    closeDialog() {
      //    this.$refs.form.reset();
      if (this.folderTagName)
        this.folderName = this.$store.getters.foldersList[this.folderTagName];
      this.$emit("closeDialog");
    },
    CreateFolderFn() {
      if (this.folderName.length == 0)
        return this.$toast.error("Please provide folder name");

      if (this.folderName.length >= 30)
        return this.$toast.error("Folder name should be with in 30 characters");

      this.createFolderLoading = true;
      if (this.folderTagName && this.folderTagName.length) {
        this.$store
          .dispatch("RENAME_FOLDER", {
            folderName: this.folderName,
            folderTag: this.folderTagName,
          })
          .then(() => {
            this.createFolderLoading = false;
            this.$emit("closeDialog");

            this.$toast.success("Folder Created Successfully");
          })
          .catch((error) => {
            this.$toast.error(error);
            this.createFolderLoading = false;
          });
      } else {
        this.$store
          .dispatch("CREATE_FOLDER", {
            folderName: this.folderName,
          })
          .then(() => {
            this.createFolderLoading = false;
            this.$emit("closeDialog");
            this.folderName = "";
            this.$toast.success("Folder Created Successfully");
          })
          .catch((error) => {
            console.error("error: ", error);
            this.$toast.error(error);
            this.createFolderLoading = false;
          });
      }
    },
  },
};
</script>
<style scoped></style>
