var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"full-width":"","fill-height":""}},[(!_vm.isInitialLoading)?_c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"px-5 py-4 glb-border-top",attrs:{"elevation":"4","rounded":"","color":_vm.$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6',"dark":_vm.$store.getters.theme === 'dark' ? true : false}},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"btn-disable px-0",attrs:{"elevation":"0","color":"transparent","x-large":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-folder")]),_c('span',{},[_vm._v(_vm._s(_vm.folderName)+" ")])],1)],1),_c('v-col',{staticClass:"px-sm-0 ml-auto",attrs:{"cols":"auto","sm":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.createFolderDialog = true}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-pencil")])],1)]}}],null,false,4218464040)},[_c('span',[_vm._v("Rename")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.deleteFolderLoading,"icon":""},on:{"click":function($event){return _vm.deleteFolder()}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-delete")])],1)]}}],null,false,2415011168)},[_c('span',[_vm._v("Delete")])])],1),_c('v-spacer',{staticClass:"d-none d-sm-flex"}),(_vm.totalCount)?_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-body-2"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center","no-gutters":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" Refresh ")],1),_c('v-col',{attrs:{"cols":"auto "}},[_vm._v(" "+_vm._s(_vm.from)+" - "+_vm._s(_vm.to)+" of "+_vm._s(_vm.totalCount)+" ")]),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.from > 1 ? false : true},on:{"click":function($event){return _vm.getFolderData(false, --_vm.options.page)}}},[_c('v-icon',[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.to >= _vm.totalCount ? true : false}},[_c('v-icon',{on:{"click":function($event){return _vm.getFolderData(false, ++_vm.options.page)}}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1)]):_vm._e()],1)],1),_c('v-card',{staticClass:"mail-view-box glb-border-top my-5",attrs:{"elevation":"0","dark":_vm.$store.getters.theme === 'dark' ? true : false,"color":_vm.$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'}},[(_vm.showMails.length == 0)?_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-text',[_vm._v(" No Mails Found ")])],1):_vm._e(),_c('v-data-table',{staticClass:"mail-data-table",attrs:{"headers":_vm.headers,"items":_vm.showMails,"options":_vm.options,"hide-default-footer":"","hide-default-header":"","server-items-length":_vm.totalCount,"loading":_vm.isInitialLoading,"footer-props":{
          'items-per-page-options': [50],
          'items-per-page-text': 'Mails to show',
        },"item-key":"parentId"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
        var items = ref.items;
return _vm._l((items.entries()),function(ref){
        var index = ref[0];
        var item = ref[1];
return _c('tr',{key:item.parentId,staticClass:"tr",class:item.seen && item.seen.includes(_vm.userInfo.nickName)
                ? 'read-mail'
                : ''},[_c('td',{staticClass:"td td-mail",attrs:{"title":_vm.getSingleUserInfo(item.receiver[0])},on:{"click":function($event){return _vm.checkItem(item, index)}}},[_c('v-list-item',{staticClass:"px-0"},[(
                    (_vm.userInfo.nickName == item.sender ||
                      _vm.userInfo.domains.includes(item.sender)) &&
                    item.receiver.length == 1
                  )?[_c('v-avatar',{staticClass:"main-btn mr-3",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(item.receiver[0].slice(1, 3).toUpperCase())+" ")])]:(
                    _vm.userInfo.nickName == item.sender ||
                    _vm.userInfo.domains.includes(item.sender)
                  )?[_c('span',{staticClass:"multi-avatar"},[_c('v-avatar',{staticClass:"main-btn mr-3 first-avatar",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(item.receiver[0].slice(1, 3).toUpperCase())+" ")]),_c('v-avatar',{staticClass:"main-btn second-avatar",attrs:{"size":"40"}},[_vm._v(" ## ")])],1)]:[_c('v-avatar',{staticClass:"main-btn mr-3",attrs:{"size":"40"}},[_vm._v(" "+_vm._s(item.sender ? item.sender.slice(1, 3).toUpperCase() : "##")+" ")])],_c('v-list-item-content',{staticClass:"py-0"},[_c('v-list-item-title',[(
                        _vm.userInfo.nickName == item.sender ||
                        _vm.userInfo.domains.includes(item.sender)
                      )?_c('span',_vm._l((item.receiver),function(rec,index){return _c('span',{key:index,attrs:{"title":_vm.getSingleUserInfo(rec, item)}},[_vm._v(" "+_vm._s(rec)+" "),(index !== item.receiver.length - 1)?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('span',{attrs:{"title":_vm.getSingleUserInfo(item.sender, item)}},[_vm._v(" "+_vm._s(item.sender)+" ")])]),_c('div',{staticClass:"mt-2"},[(_vm.$route.name !== 'inbox' && _vm.$route.name !== 'sent')?_c('v-chip',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.userInfo.nickName == item.sender || _vm.userInfo.domains.includes(item.sender) ? "sent" : "inbox")+" ")]):_vm._e(),(item.attachments.length)?_c('v-btn',{staticClass:"mr-1 pa-0",attrs:{"elevation":"0","min-width":"auto","color":"transparent","title":'Has ' + item.attachments.length + ' attachment(s)'}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")]),_c('small',[_vm._v("x")]),_vm._v(" "+_vm._s(item.attachments.length)+" ")],1):_vm._e(),_c('span',{staticClass:"mail-subject"},[_vm._v(_vm._s(item.subject))])],1)],1)],2)],1),_c('td',{staticClass:"td td-time"},[_c('span',{staticClass:"additions"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.undoFile(item)}}},on),[_c('v-icon',[_vm._v(" mdi-package-up ")])],1)]}}],null,true)},[_c('span',[_vm._v("Undo")])])],1),_c('span',{staticClass:"time"},[_vm._v(" "+_vm._s(_vm.timeFormate(item.timestamp))+" ")])])])})}}],null,false,1312605523)}),_c('v-dialog',{attrs:{"width":"500","max-width":"90%","close":_vm.createFolderDialog},on:{"update:close":function($event){_vm.createFolderDialog=$event}},model:{value:(_vm.createFolderDialog),callback:function ($$v) {_vm.createFolderDialog=$$v},expression:"createFolderDialog"}},[_c('edit-folder',{attrs:{"createFolderDialog":_vm.createFolderDialog,"folderTagName":_vm.$route.params.tagName},on:{"closeDialog":function($event){_vm.createFolderDialog = false}}})],1)],1)],1):_c('v-card',{staticClass:"mail-view-box glb-border-top pa-5 text-center",attrs:{"width":"100%","dark":_vm.$store.getters.theme === 'dark' ? true : false,"color":_vm.$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'}},[_c('div',[_vm._v("Fetching "+_vm._s(_vm.folderName)+" Mails, Please wait")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }